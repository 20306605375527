<template>
    <div class="w-full my-4 bg-green-50 border-green-200 border rounded-lg p-4">
        <div class="flex ">
            <strong class="w-full my-auto">{{ document.name }}</strong>

            <a
                :href="`http://localhost:8000${document.document}`"
                target="_blank"
            >
                <t-button
                    class=" hover:bg-green-400  bg-green-600 mx-0 my-0 px-0 py-0 rounded "
                >
                    <font-awesome-icon
                        class="text-green-100 mx-2  "
                        :icon="['fal', 'download']"
                        size="1x"
                    />
                </t-button>
            </a>
        </div>
        <div v-if="document.site">
            Site: <span>{{ document.site.name }}</span>
        </div>
        <div v-if="document.area">
            Area: <span>{{ document.area.name }}</span>
        </div>
        <div v-if="document.category">
            Category: <span>{{ document.category.name }}</span>
            <div>Type: {{ document.category.type.name }}</div>
        </div>
        <div v-if="document.originator">
            Originator: <span>{{ document.originator.name }}</span>
        </div>
        <div v-if="document.discipline">
            Discipline: <span>{{ document.discipline.name }}</span>
        </div>
        <div v-if="document">
            Revision: <span>{{ document.revision }}</span>
        </div>
    </div>
</template>

<style></style>

<script>
export default {
    name: "Document",
    props: [
        "document",
        "categoryFilter",
        "originatorFilter",
        "disciplineFilter"
    ],

    data() {
        return {
            documentDetail: {}
        };
    },
    watch: {},
    async mounted() {},
    methods: {}
};
</script>
