import axios from "axios";
import Cookies from "js-cookie";

function getApiURL(path) {
    return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
    namespaced: true,
    state: {
        token: {
            tokenType: Cookies.get("Bearer") ? "Bearer" : undefined,
            accessToken: Cookies.get("Bearer"),
            expiresIn: "",
            scope: "",
            refreshToken: ""
        },
        error: null
    },
    getters: {},
    mutations: {
        setToken(state, token) {
            state.token.tokenType = token.token_type;
            state.token.accessToken = token.access_token;
            state.token.expiresIn = token.expires_in;
            state.token.scope = token.scope;
            state.token.refreshToken = token.refresh_token;
        },
        setError(state, error) {
            state.error = error.data.detail;
        },
        clearError(state) {
            state.error = null;
        }
    },
    actions: {
        async fetchTokenFromRefreshToken({ commit }, payload) {
            await axios
                .post(
                    getApiURL("auth/token/"),
                    `grant_type=refresh_token&refresh_token=${payload}&client_id=${process.env.VUE_APP_CLIENT_ID}&client_secret=${process.env.VUE_APP_CLIENT_SECRET}`
                )

                .then(response => {
                    commit("clearError", "");

                    commit("setToken", response.data);

                    Cookies.set(
                        response.data.token_type,
                        response.data.access_token,
                        {
                            expires: 7
                        }
                    );

                    //set cookie for refreshtoken
                    Cookies.set("refreshToken", response.data.refresh_token);

                    axios.interceptors.request.use(req => {
                        req.headers.common = {
                            Authorization: `${response.data.token_type} ${response.data.access_token}`
                        };

                        return req;
                    });
                })
                .catch(error => {
                    commit("setError", error.response);
                });
        },
        async fetchToken({ commit }, payload) {
            await axios
                .post(
                    getApiURL("auth/token/"),
                    `grant_type=password&username=${payload.username}&password=${payload.password}&client_id=${process.env.VUE_APP_CLIENT_ID}&client_secret=${process.env.VUE_APP_CLIENT_SECRET}`
                )
                .then(response => {
                    commit("clearError", "");

                    commit("setToken", response.data);
                    //set cookie for token
                    Cookies.set(
                        response.data.token_type,
                        response.data.access_token,
                        {
                            expires: 7
                        }
                    );
                    //set cookie for refreshtoken
                    Cookies.set("refreshToken", response.data.refresh_token, {
                        expires: 7
                    });

                    axios.interceptors.request.use(req => {
                        req.headers.common = {
                            Authorization: `${response.data.token_type} ${response.data.access_token}`
                        };

                        return req;
                    });
                })
                .catch(error => {
                    commit("setError", error.response);
                });
        }
    }
};
