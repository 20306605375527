import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import ForgePlugin from "./forge/ForgePlugin.js";
import Cookies from "js-cookie";
import axios from "axios";
import VueTailwind from "vue-tailwind"; //Custom load only the components you select below
//import VueTailwind from 'vue-tailwind/dist/full' // Loads the entire library
import moment from "moment";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";

library.add(fas);
library.add(fal);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.moment = moment;

if (Cookies.get("Bearer")) {
    axios.interceptors.request.use(function(config) {
        config.headers.common = {
            Authorization: `Bearer ${Cookies.get("Bearer")}`
        };

        return config;
    });
}

axios.interceptors.response.use(
    function(response) {
        return response;
    },
    async function(error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            // unauthed
            originalRequest._retry = true;

            await store.dispatch(
                "account/fetchTokenFromRefreshToken",
                `${Cookies.get("refreshToken")}`
            );
            axios.interceptors.request.use(function(config) {
                config.headers.common = {
                    Authorization: `Bearer ${Cookies.get("Bearer")}`
                };

                // config.headers["x-csrftoken"] = document.querySelector(
                //     'input[name="csrftoken"]'
                // ).value;
                return config;
            });
        } else {
            if (router.currentRoute.name != "Login") router.push("/login");
        }

        return Promise.reject(error);
    }
);

import {
    TButton,
    TInput,
    TTextarea,
    TSelect,
    // TRadio,
    // TCheckbox,
    // TButton,
    //TInputGroup,
    //TCard, //There are some problems that i can't understand for now
    // TAlert,
    TModal,
    //TDropdown,
    TRichSelect,
    // TPagination,
    // TTag,
    // TRadioGroup,
    // TCheckboxGroup,
    TTable
    // TDatepicker,
    // TToggle,
    // TDialog,
} from "vue-tailwind/dist/components";

const settings = {
    // Use the following syntax
    // {component-name}: {
    //   component: {importedComponentObject},
    //   props: {
    //     {propToOverride}: {newDefaultValue}
    //     {propToOverride2}: {newDefaultValue2}
    //   }
    // }

    "t-modal": {
        component: TModal,
        props: {
            fixedClasses: {
                overlay:
                    "z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50",
                wrapper: "relative mx-auto z-50 max-w-lg px-3 py-12",
                modal: "overflow-visible relative  rounded",
                body: "p-3",
                header: "border-b p-3 rounded-t",
                footer: " p-3 rounded-b",
                close:
                    "flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
            },
            classes: {
                overlay: "bg-black",
                wrapper: "",
                modal: "bg-white shadow",
                body: "p-3",
                header: "border-gray-100",
                footer: "bg-gray-100",
                close: "bg-gray-100 text-gray-600 hover:bg-gray-200",
                closeIcon: "fill-current h-4 w-4",
                overlayEnterClass: "opacity-0",
                overlayEnterActiveClass: "transition ease-out duration-100",
                overlayEnterToClass: "opacity-100",
                overlayLeaveClass: "opacity-100",
                overlayLeaveActiveClass: "transition ease-in duration-75",
                overlayLeaveToClass: "opacity-0",
                enterClass: "",
                enterActiveClass: "",
                enterToClass: "",
                leaveClass: "",
                leaveActiveClass: "",
                leaveToClass: ""
            },
            variants: {
                danger: {
                    overlay: "bg-red-100",
                    header: "border-red-50 text-red-700",
                    close:
                        "bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border",
                    modal: "bg-white border border-red-100 shadow-lg",
                    footer: "bg-red-50"
                }
            }
        }
    },

    "t-input": {
        component: TInput,
        props: {
            classes: `transition duration-500   ease-in-out focus:outline-none rounded 
        focus:ring border border-gray-500 focus:border-green-600 focus:ring-green-600 
        p-2  font-sans disabled:opacity-50 disabled:cursor-not-allowed`
            // ...More settings
        }
    },
    "t-richselect": {
        component: TRichSelect,
        props: {
            classes: {
                wrapper: "mx-5 my-1",
                buttonWrapper: "",
                selectButton:
                    "w-full flex text-left justify-between items-center px-3 py-2 text-black transition duration-100 ease-in-out border border-green-600 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
                selectButtonLabel: "",
                selectButtonTagWrapper: "-mx-2 -my-2.5 py-1 pr-8 ",
                selectButtonTag:
                    "bg-blue-500 block disabled:cursor-not-allowed disabled:opacity-50 duration-100 ease-in-out focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded shadow-sm text-sm text-white transition white-space-no m-0.5 max-w-full overflow-hidden h-8 flex items-center",
                selectButtonTagText: "px-3",
                selectButtonTagDeleteButton:
                    "-ml-1.5 h-full hover:bg-blue-600 hover:shadow-sm inline-flex items-center px-2 transition",
                selectButtonTagDeleteButtonIcon: "",
                selectButtonPlaceholder: "text-gray-400",
                selectButtonIcon: "",
                selectButtonClearButton:
                    "hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out",
                selectButtonClearIcon: "",
                dropdown:
                    "-mt-1 bg-white border-b border-green-600  border-l border-r rounded-b shadow-sm",
                dropdownFeedback: "pb-2 px-3 text-gray-400 text-sm",
                loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
                optionsList: "",
                searchWrapper: "p-2 placeholder-gray-400",
                searchBox:
                    "px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-green-600 ",
                optgroup:
                    "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
                option: "",
                disabledOption: "",
                highlightedOption: "bg-blue-100",
                selectedOption:
                    "font-semibold bg-gray-100 bg-blue-500 font-semibold text-white",
                selectedHighlightedOption:
                    "font-semibold bg-gray-100 bg-blue-600 font-semibold text-white",
                optionContent: "flex justify-between items-center px-3 py-2",
                optionLabel: "",
                selectedIcon: "",
                enterClass: "opacity-0",
                enterActiveClass: "transition ease-out duration-100",
                enterToClass: "opacity-100",
                leaveClass: "opacity-100",
                leaveActiveClass: "transition ease-in duration-75",
                leaveToClass: "opacity-0"
                // ...More settings
            }
        }
    },
    // "t-card": {
    //   component: TCard,
    //   props: {
    //     classes: "border-2 text-gray-800 rounded bg-white shadow w-full m-4 p-4",
    //     // ...More settings
    //   },
    // },
    "t-button": {
        component: TButton,
        props: {
            classes:
                "focus:outline-none  hover:bg-green-400  text-white py-1.5 px-2  "
            // ...More settings
        }
    },
    "t-table": {
        component: TTable,
        props: {
            classes: {
                table:
                    "min-w-full divide-y divide-green-100 shadow-sm border-green-200 border",
                thead: "",
                theadTr: "",
                theadTh:
                    "px-3 py-2 font-semibold text-left bg-green-100 border-b",
                tbody: "bg-white divide-y divide-green-100",
                tr: "",
                td: "px-3 py-2 whitespace-no-wrap",
                tfoot: "",
                tfootTr: "",
                tfootTd: ""
            },
            variants: {
                thin: {
                    td: "p-1 whitespace-no-wrap text-sm",
                    theadTh:
                        "p-1 font-semibold text-left bg-green-100 border-b text-sm"
                }
            }
        }
    },
    "t-select": {
        component: TSelect,
        props: {
            wrapped: false,
            classes: `block w-full pl-3 pr-10 py-2 transition duration-100 ease-in-out border border-green-600 rounded shadow-sm focus:ring-2 focus:ring-green-600 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed`
            // {
            // wrapper: "relative w-full ",
            // input:
            //     "focus:outline-none bg-green-600 hover:bg-green-400 w-full text-white font-bold p-2 cursor-default rounded transition duration-100 ease-in-out block mr-8",
            // arrowWrapper:
            //     "pointer-events-none absolute inset-y-0 right-0 flex items-center px-2",
            // arrow: "fill-current text-white h-4 w-4"

            // }

            // ...More settings
        }
    },
    "t-textarea": {
        component: TTextarea,
        props: {
            classes: "border-2 block w-full rounded text-gray-800"
            // ...More settings
        }
    }
    // ...Rest of the components
};

Vue.config.productionTip = false;
Vue.use(VueTailwind, settings);
Vue.use(ForgePlugin);
Vue.use(require("vue-moment"));

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
