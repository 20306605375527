import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Home from "../views/Home.vue";
import Cookies from "js-cookie";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/logout",
        name: "Logout",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        redirect: { name: "Login" }
    },
    {
        path: "/login",
        name: "Login",
        async beforeEnter(to, from, next) {
            await store.commit("account/setToken", {
                tokenType: null,
                accessToken: null
            });
            Cookies.remove("bearer");
            next();
        },
        component: () => import("../views/Login.vue")
    }
];

const router = new VueRouter({
    routes
});

export default router;
