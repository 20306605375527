<template>
    <div class="forge" ref="forge"><slot name="popupInstance" /></div>
</template>

<style>
.forge {
    position: relative;
    height: 100%;
    width: 100%;
}
/*.forge {
   width: 100%;
  height: 61.3vh; 
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; 
}*/

/* Toolbar background colour*/
.adsk-viewing-viewer.dark-theme .adsk-control-group {
    /* background-color: rgb(5, 150, 105); */
    outline-color: rgb(52, 211, 153);
    /* color: rgb(52, 211, 153); */
}

/* Toolbar icon when pressed colour*/
.adsk-viewing-viewer.dark-theme .adsk-button.active,
.adsk-viewing-viewer.dark-theme .adsk-button:focus {
    color: rgb(52, 211, 153);
    /* border-top-color: rgb(52, 211, 153);
    border-right-color: rgb(52, 211, 153);
    border-bottom-color: rgb(52, 211, 153);
    border-left-color: rgb(52, 211, 153); */
}
/* Toolbar hover state  colour*/
.adsk-viewing-viewer.dark-theme .adsk-button:hover {
    color: rgb(52, 211, 153);
    border-top-color: rgb(52, 211, 153);
    border-right-color: rgb(52, 211, 153);
    border-bottom-color: rgb(52, 211, 153);
    border-left-color: rgb(52, 211, 153);
}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import axios from "axios";

export default {
    data() {
        return {
            unsubscribe: null,
            toggleLastState: true
        };
    },

    async mounted() {
        this.unsubscribe = this.$store.subscribe(this.subscribe, {
            prepend: true
        });
        await this.initalizeViewer();
    },

    destroyed() {
        this.unsubscribe();
        this.destroyViewer();
    },

    computed: {
        ...mapState({
            tokenURL: state => state.viewer.tokenURL,
            modelURL: state => state.viewer.modelURL,
            document: state => state.viewer.document,
            documentLoaded: state => state.viewer.documentLoaded,
            viewable: state => state.viewer.viewable,
            viewableGeometryGuid: state => state.viewer.viewableGeometryGuid,
            urn: state => state.viewer.urn,
            extensions: state => state.viewer.extensions,
            selectedItems: state => state.viewer.selectedItems,
            isolatedItems: state => state.viewer.isolatedItems,
            devices: state => state.viewer.devices,
            viewer: state => state.viewer.viewer,
            lastSelectedItem: state => state.viewer.lastSelectedItem,
            shouldCameraReposition: state =>
                state.viewer.shouldCameraReposition,
            deviceMetaData: state => state.viewer.deviceMetaData
        }),

        ...mapGetters({
            getExtensionById: "viewer/getExtensionById",
            getExtensionByIndex: "viewer/getExtensionByIndex",
            loadedExtensionsId: "viewer/loadedExtensionsId",
            loadedExtensions: "viewer/loadedExtensions",
            getRoomTemperatureColourByDbId:
                "viewer/getRoomTemperatureColourByDbId",
            getRooms: "viewer/getRooms",
            getDeviceMetaDataByClickable: "viewer/getDeviceMetaDataByClickable"
        })
    },

    methods: {
        ...mapMutations({
            setDocument: "viewer/setDocument",
            setDocumentLoaded: "viewer/setDocumentLoaded",
            setViewer: "viewer/setViewer",
            updateExtension: "viewer/updateExtension",
            updateExtensionInstance: "viewer/updateExtensionInstance",
            pushSelectedItem: "viewer/pushSelectedItem",
            emptySelectedItems: "viewer/emptySelectedItems",
            updateExtensionLoaded: "viewer/updateExtensionLoaded",
            updateLastSelectedItem: "viewer/updateLastSelectedItem",
            updateShouldCameraReposition: "viewer/updateShouldCameraReposition",
            setHotSpot: "viewer/setHotSpot"
        }),

        ...mapActions({
            fetchViewable: "viewer/fetchViewable"
        }),

        //checks for any mutations where a field is being updated
        subscribe(mutation) {
            if (mutation.type === "viewer/updateField") {
                this.selectUpdateMethod(mutation.payload.path);
            }
        },

        // takes a given path and extracts the extension from it and updates it
        selectUpdateMethod(path) {
            const pathParts = path.split(/[.[\]]+/);
            if (pathParts.length === 3 && pathParts[0] === "extensions") {
                const extensionIndex = parseInt(pathParts[1], 10);
                const extension = this.getExtensionByIndex(extensionIndex);
                this.updateViewerExtension(extension);
            }
        },

        //loads and unloads and extension
        updateViewerExtension(extension) {
            if (extension.loaded) this.loadViewerExtension(extension.id);
            else this.unloadViewerExtension(extension.id);
        },

        // This checks for selection of a viewer element
        handleSelectionChangedEvent(event) {
            for (const item of event.nodeArray) {
                if (
                    this.selectedItems.filter(
                        selectedItem =>
                            parseInt(selectedItem.id, 10) === parseInt(item, 10)
                    ).length === 0
                )
                    this.pushSelectedItem({ id: item, name: item });
            }
            this.updateLastSelected(event.dbIdArray[0]);
        },

        //[decouple] This is specific to devices, perhaps it can be moved to IoT Device
        //handles a given device id, and sets accordian details and camera position for it
        updateLastSelected(incomingId) {
            const device = this.getDeviceMetaDataByClickable(incomingId);

            if (device) {
                this.updateLastSelectedItem(device.accordianIndex);
                this.viewer.clearSelection();
                this.updateShouldCameraReposition(false);
            }
        },

        unloadHandler() {
            this.emptySelectedItems();
        },

        //[decouple] Can we have the options on the store as part of the extension? then each extension handles it own options state rather than having it setup here, or extension manager?
        //passes options to extensions
        getExtensionOptions(extensionId) {
            // TODO: Fix this so it is more generic
            if (extensionId === "BIMAcademy.Event.Handler") {
                return {
                    unloadHandler: this.unloadHandler,
                    eventHandlers: {
                        // Autodesk.Viewing.SELECTION_CHANGED_EVENT
                        selection: this.handleSelectionChangedEvent,
                        escape: this.unloadHandler
                    }
                };
            } else if (extensionId === "BIMAcademy.ColourObjects") {
                return {
                    vue: this
                };
            } else if (extensionId === "BIMAcademy.PointTracker") {
                return {
                    vue: this
                };
            } else if (extensionId === "BIMAcademy.HTMLPoints") {
                return {
                    vue: this
                };
            } else {
                return {};
            }
        },

        loadViewerExtension(extensionId) {
            this.viewer
                .loadExtension(
                    extensionId,
                    this.getExtensionOptions(extensionId)
                )
                .then(instance => {
                    const extension = this.getExtensionById(extensionId);
                    this.updateExtensionInstance({ extension, instance });
                });
        },

        unloadViewerExtension(extensionId) {
            const extension = this.getExtensionById(extensionId);
            this.updateExtensionInstance({ extension, instance: null });
            return this.viewer.unloadExtension(extensionId);
        },

        updateViewerExtensionInstance(instance) {
            const extension = this.getExtensionById(instance.id);
            this.updateExtensionInstance({ extension, instance });
        },

        loadViewerExtensions() {
            // If `this.viewer` already has extensions loaded
            const viewerExtensions = this.viewer.getLoadedExtensions();
            for (const extensionId in viewerExtensions) {
                // If the extensions is to ba manaaged by us
                const extensions = this.extensions.filter(
                    e => e.id === extensionId
                );
                if (extensions.length) {
                    // Set `loaded` on $store.extensions
                    this.updateExtensionLoaded({
                        extension: extensions[0],
                        loaded: true
                    });
                }
            }

            // If there are any managed extensions that are flagged as `loaded` but have not yet been loaded
            this.loadedExtensions
                .filter(extension => extension.instance === null)
                .map(extension => extension.id)
                .map(this.loadViewerExtension);
        },

        async loadViewerExtensionInstance(extensionId) {
            await this.viewer.getExtension(
                extensionId,
                this.updateViewerExtensionInstance
            );
        },

        initalizeViewer() {
            const Autodesk = this.$forge;
            const options = {
                env: "AutodeskProduction",
                api: "derivativeV2_EU",
                getAccessToken: this.getAccessToken
            };

            Autodesk.Viewing.Initializer(options, () =>
                this.initializerCallback()
            );
        },

        initializerCallback() {
            const Autodesk = this.$forge;
            const htmlDiv = this.$refs.forge;
            const viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);

            const startedCode = viewer.start();

            if (startedCode > 0) {
                console.log("Autodesk.Viewing.Initializer failed");
                return;
            }

            viewer.addEventListener(
                Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT,
                () => {
                    this.viewer.navigation.toPerspective();
                }
            );

            this.setViewer(viewer);
            this.loadModel();
        },

        destroyViewer() {
            const Autodesk = this.$forge;
            this.viewer.finish();
            this.setViewer(null);
            this.setDocument(null);
            this.setDocumentLoaded(false);
            Autodesk.Viewing.shutdown();
        },

        async loadModel() {
            const Autodesk = this.$forge;
            const documentId = await this.getDocumentId();

            Autodesk.Viewing.Document.load(
                documentId,
                viewerDocument => this.onLoadModelSuccess(viewerDocument),
                () => this.onLoadModelFailure()
            );

            await this.viewer.waitForLoadDone();
            this.loadViewerExtensions();
            this.setDocumentLoaded(true);
        },

        onLoadModelSuccess(viewerDocument) {
            this.setDocument(viewerDocument);
            const defaultGeometryGuid = this.viewableGeometryGuid;
            const modelRoot = this.document.getRoot();
            const defaultModel = defaultGeometryGuid
                ? modelRoot.findByGuid(defaultGeometryGuid)
                : modelRoot.getDefaultGeometry();
            this.viewer.loadDocumentNode(this.document, defaultModel, {
                useConsolidation: false
            });
        },

        onLoadModelFailure() {
            console.error("Failed fetching Forge manifest");
        },

        //[decouple] Tried to pull this into the store but can't get it to work, might need to save the token and expires in on the store then return the method here
        //because it appears the store can't handle sending back a method
        async getAccessToken(onTokenReady) {
            await axios
                .get(this.tokenURL)
                .then(res => {
                    onTokenReady(res.data.access_token, res.data.expires_in);
                })
                .catch(error => {
                    console.error(error);
                });
        },

        async getDocumentId() {
            await this.fetchViewable();
            return this.urn;
        }
    }
};
</script>
