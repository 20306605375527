<template>
    <div class="mx-4 py-1">
        <div
            class=" flex  flex-none bg-green-50 border-green-200 border rounded-lg"
        >
            <!--  -->

            <div class="w-1/12 flex-none">
                <div class=" m-2 " v-if="data.notification.level === 'error'">
                    <!-- xl:text-2xl lg:text-xl -->
                    <font-awesome-icon
                        class="text-red-500 transition ease-in-out "
                        :icon="['fal', 'times-circle']"
                        size="1x"
                    />
                    <!-- red x or orange triangle exclamation -->
                </div>
                <div class=" m-2 " v-if="data.notification.level === 'warning'">
                    <!-- xl:text-2xl lg:text-xl -->
                    <font-awesome-icon
                        class="text-yellow-500 transition ease-in-out "
                        :icon="['fal', 'exclamation-triangle']"
                        size="1x"
                    />
                    <!-- red x or orange triangle exclamation -->
                </div>
                <div class=" m-2 " v-if="data.notification.level === 'info'">
                    <!-- xl:text-2xl lg:text-xl -->
                    <font-awesome-icon
                        class="text-blue-500 transition ease-in-out "
                        :icon="['fal', 'info-circle']"
                        size="1x"
                    />
                    <!-- red x or orange triangle exclamation -->
                </div>
            </div>
            <div class="flex-grow ">
                <div class=" w-full m-2 ">
                    <!-- xl:text-2xl lg:text-xl -->
                    <b>{{ data.notification.message }}</b>
                    <!-- <b>Machine running slow</b>
                                <b>Acceptable</b>
                                <b>Machine Broken down</b> -->
                </div>
            </div>
            <div class="flex-none  mx-2" v-if="showMarkRead">
                <div class="  m-2 ">
                    <!-- xl:text-2xl lg:text-xl -->
                    <a
                        href="#"
                        class="hover:text-green-400"
                        @click.prevent="markRead"
                        >Mark Read</a
                    >
                    <!-- <b>Machine running slow</b>
                                <b>Acceptable</b>
                                <b>Machine Broken down</b> -->
                </div>
            </div>
            <div class="  mx-2  " v-else>
                <div class=" m-2 text-justify ">
                    <!-- xl:text-2xl lg:text-xl -->
                    <span class="text-xs text-gray-700  ">
                        {{ data.read_at | moment("DD/MM/YYYY @ HH:MM") }}
                    </span>
                    <!-- <b>Machine running slow</b>
                                <b>Acceptable</b>
                                <b>Machine Broken down</b> -->
                </div>
            </div>
        </div>
    </div>
</template>

<style>
/* Fade in and out transitions */

.fade-out-in-enter-active,
.fade-out-in-leave-active {
    transition: opacity 0.5s;
}

.fade-out-in-enter-active {
    transition-delay: 0.5s;
}

.fade-out-in-enter,
.fade-out-in-leave-to {
    opacity: 0;
}

/* Slide Transitions */

.slide-leave-active,
.slide-enter-active {
    transition: 1s;
    transition-property: height, opacity, transform;
}
.slide-enter {
    transform: translate(100%, 0);
}
.slide-leave-to {
    transform: translate(100%, 0);
    opacity: 0;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
export default {
    props: { data: Object, showMarkRead: { type: Boolean, default: true } },
    data() {
        return {};
    },

    computed: {
        ...mapState({
            alerts: state => state.alerts.alerts
        })
    },
    async mounted() {},
    methods: {
        ...mapActions({
            markAlertRead: "alerts/markRead"
        }),

        async markRead() {
            await this.markAlertRead({
                assemblies: null,
                recent_only: false,
                guids: this.data.notification.guid
            });

            this.$emit("updateAlerts");
        }
    }
};
</script>
