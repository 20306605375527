<template>
    <div class="mr-4 ml-4 mb-4 h-36 ">
        <div class=" flex flex-row flex-none h-32 ">
            <div class="align-middle my-6 px-2">
                <font-awesome-icon
                    class="text-gray-500 transition ease-in-out hover:text-green-600"
                    :icon="['fal', 'angle-left']"
                    size="5x"
                    @click="calcStep(-1)"
                />
            </div>
            <div class="flex flex-grow ">
                <transition-group name="slide">
                    <!-- <transition-group name="fade-out-in"> -->
                    <div
                        class="flex align-middle h-28"
                        v-if="currentStep == 1"
                        key="1"
                        style="position:absolute; "
                    >
                        <div class="flex flex-row m-auto">
                            <div class="flex  m-auto">
                                <div class=" w-1/3 m-2  flex">
                                    <strong
                                        class="xl:text-7xl lg:text-6xl text-green-500 text-opacity-50"
                                        >{{ weather.humidity }}</strong
                                    >
                                    <div class="xl:text-2xl lg:text-xl">
                                        %
                                    </div>
                                </div>
                            </div>
                            <div class="flex-col m-auto">
                                <div class=" w-full xl:text-2xl lg:text-xl">
                                    <font-awesome-icon
                                        class="text-green-600 transition ease-in-out  mx-1"
                                        :icon="['fal', 'humidity']"
                                        size="1x"
                                    />
                                    <b>Humidity</b>
                                </div>
                                <div class=" w-full xl:text-lg lg:text-xl">
                                    Skouries, Greece
                                </div>
                                <div
                                    class=" flex  flex-shrink text-xs text-gray-400"
                                >
                                    Last updated,
                                    {{
                                        weather.reference_time
                                            | moment("MMMM Do YYYY HH:MM")
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex align-middle h-28"
                        v-if="currentStep == 2"
                        key="2"
                        style="position:absolute; "
                    >
                        <div class="flex flex-row m-auto">
                            <div class="flex m-auto">
                                <div class=" w-1/3 m-2 flex">
                                    <strong
                                        class="xl:text-7xl lg:text-5xl text-green-500 text-opacity-50"
                                        >{{
                                            Math.round(weather.temperature.temp)
                                        }}</strong
                                    >
                                    <div class="xl:text-6xl lg:text-4xl ">
                                        &deg;
                                    </div>
                                </div>
                            </div>
                            <div class="flex-col m-auto">
                                <div class=" w-full xl:text-2xl lg:text-xl">
                                    <font-awesome-icon
                                        class="text-green-600 transition ease-in-out  mx-1"
                                        :icon="['fal', 'temperature-hot']"
                                        size="1x"
                                    />
                                    <b>Temperature</b>
                                </div>
                                <div class=" w-full xl:text-lg lg:text-xl">
                                    Skouries, Greece
                                </div>
                                <div class="w-full  text-xs text-gray-400">
                                    Last updated,
                                    {{
                                        weather.reference_time
                                            | moment("MMMM Do YYYY HH:MM")
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>

            <div class="flex flex-none h-auto my-6">
                <font-awesome-icon
                    class="text-gray-500 transition ease-in-out hover:text-green-600"
                    :icon="['fal', 'angle-right']"
                    size="5x"
                    @click="calcStep(+1)"
                />
            </div>
        </div>
    </div>
</template>

<style>
/* Fade in and out transitions */

.fade-out-in-enter-active,
.fade-out-in-leave-active {
    transition: opacity 0.5s;
}

.fade-out-in-enter-active {
    transition-delay: 0.5s;
}

.fade-out-in-enter,
.fade-out-in-leave-to {
    opacity: 0;
}

/* Slide Transitions */

.slide-leave-active,
.slide-enter-active {
    transition: 1s;
    transition-property: height, opacity, transform;
}
.slide-enter {
    transform: translate(100%, 0);
}
.slide-leave-to {
    transform: translate(100%, 0);
    opacity: 0;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
export default {
    props: {},
    data() {
        return {
            currentStep: 1,
            selectedPanel: "temperature"
        };
    },
    computed: {
        ...mapState({
            weather: state => state.weather.weather
        })
    },
    async mounted() {
        await this.fetchWeather();
    },
    methods: {
        ...mapActions({
            fetchWeather: "weather/fetchWeather"
        }),

        calcStep(direction) {
            this.currentStep += direction;

            // fix extremeties
            if (this.currentStep > 2) this.currentStep = 1;
            if (this.currentStep < 1) this.currentStep = 2;
        },

        changePanel(selectedPanel) {
            if (selectedPanel == "temperature") {
                this.currentStep = 1;
            }
            if (selectedPanel == "humidity") {
                this.currentStep = 2;
            }
        }
    }
};
</script>
