import axios from "axios";
import Vue from "vue";

function getApiURL(path) {
    return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
    namespaced: true,
    state: {
        alerts: {},
        historicalAlerts: {},
        errorResponse: {}
    },
    getters: {},
    mutations: {
        setAlerts(state, alerts) {
            Vue.set(state, "alerts", alerts);
        },
        setHistoricalAlerts(state, alerts) {
            state.historicalAlerts = alerts;
        },
        setMarkReadResponse(state, response) {
            state.errorResponse = response;
        }
    },
    actions: {
        async fetchAlerts({ commit }, payload) {
            await axios
                .get(getApiURL("api/notifications/list"), {
                    params: payload
                })
                .then(response => {
                    commit("setAlerts", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        },
        async fetchHistoricalAlerts({ commit }, payload) {
            await axios
                .get(getApiURL("api/notifications/list"), {
                    params: {
                        assemblies: payload.assemblies[0],
                        unexpired_only: false,
                        unread_only: false,
                        recent_only: false
                    }
                })
                .then(response => {
                    commit("setHistoricalAlerts", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        },
        async markRead({ commit }, payload) {
            await axios
                .put(getApiURL("api/notifications/read"), null, {
                    params: {
                        //assemblies: payload.assemblies,
                        recent_only: false,
                        guids: payload.guids
                    }
                })
                .then(response => {
                    commit("setMarkReadResponse", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        }
    }
};
