import axios from "axios";
import Vue from "vue";

function getApiURL(path) {
    return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
    namespaced: true,
    state: {
        assembilies: {},
        assemblyItem: {},
        viewables: {}
    },
    getters: {},
    mutations: {
        setAssembilies(state, assetListing) {
            Vue.set(state, "assembilies", assetListing);
            // state.assembilies = assetListing;
        },
        setAssemblyItem(state, assemblyItem) {
            Vue.set(state, "assemblyItem", assemblyItem);
        },
        setViewables(state, viewables) {
            state.viewables = viewables;
        }
    },
    actions: {
        async fetchAssembilies({ commit }) {
            await axios
                .get(getApiURL("api/components/assembly/list"))
                .then(response => {
                    commit("setAssembilies", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        },
        async fetchAssemblyItem({ commit }, payload) {
            await axios
                .get(getApiURL(`api/components/assembly/${payload.guid}`), {
                    params: {
                        search: payload.search ? payload.search : "",
                        is_consumable: payload.is_consumable
                    }
                })
                .then(response => {
                    commit("setAssemblyItem", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        },
        async fetchViewables({ commit }) {
            await axios
                .get(
                    process.env.VUE_APP_API_BASE_URL +
                        "api/viewables/items/list"
                )
                .then(response => {
                    commit("setViewables", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        }
    }
};
