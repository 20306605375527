<template>
    <div id="app" class=" max-h-screen">
        <div
            id="nav"
            class="flex h-16 align-middle bg-gray-100 border-b border-gray-300"
            v-if="token.accessToken != null"
        >
            <div class="w-screen justify-start m-0">
                <!-- <router-link class="pr-4" to="/">Home</router-link> -->
                <img
                    alt="Logo"
                    class="mx-4 my-2 object-center h-12"
                    src="./assets/site_logo.png"
                />
            </div>
            <div class="justify-end m-auto p-4 flex ">
                <SchedulePanel></SchedulePanel>
                <alert-panel></alert-panel>
                <router-link @click.native="logout" to="/logout"
                    ><font-awesome-icon
                        class="text-gray-900 hover:text-green-600 mx-4 my-2"
                        :icon="['fal', 'sign-out']"
                        size="1x"
                /></router-link>
            </div>
        </div>

        <div class="">
            <router-view />
        </div>
    </div>
</template>
<style>
body {
    overflow-x: hidden;
}
</style>

<script>
import Cookies from "js-cookie";
import { mapState, mapMutations } from "vuex";
import AlertPanel from "./components/Sidepanel/AlertPanel.vue";
import SchedulePanel from "./components/Sidepanel/SchedulePanel.vue";
// import axios from "axios";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapState({
            token: state => state.account.token
        })
    },
    async mounted() {
        //check if token expired and boot to login screen if it has
        if (
            !this.token ||
            this.token.accessToken === undefined ||
            this.token.accessToken === null ||
            Cookies.get("refreshToken") === null
        ) {
            Cookies.remove("Bearer");
            Cookies.get("refreshToken");
            this.$router.push("/login");
        }
        // await axios
        //     .get(process.env.VUE_APP_API_BASE_URL + "api/auth/token/validate")
        //     .catch(() => {
        //         this.logout();
        //     });
    },
    components: { AlertPanel, SchedulePanel },
    methods: {
        ...mapMutations({
            setToken: "account/setToken"
        }),

        async logout() {
            await this.setToken({ tokenType: null, accessToken: null });
            Cookies.remove("Bearer");
            Cookies.remove("refreshToken");
            this.$router.push("/login");
        }
    }
};
</script>
