<template>
    <div class="homeScr flex m-auto ">
        <div class="w-2/3  ">
            <ForgeViewer>
                <!-- <template slot="popupInstance">
                    <ForgeHotSpot
                        style="display: hidden;"
                        data="index 0"
                        :index="0"
                        :dbid="12428"
                    >
              
                        <div class="flex  font-sans">
                            <div class="   m-2  flex">
                                <div class="text-xl mr-10   ">
                                    Sag Mill
                                </div>
                                <div class="text-xl mr-10   ">
                                    Utilisation:
                                </div>
                                <strong
                                    class="text-3xl text-green-500 text-opacity-50 "
                                    >97</strong
                                >
                                <div class="text-base ">%</div>
                            </div>
                        </div>
                    </ForgeHotSpot>

       
                </template> -->
            </ForgeViewer>
        </div>

        <div class="flex-1 flex w-3/6 ">
            <div class="flex-1">
                <div>
                    <div class="border-b border-gray-300 h-32 mb-4 w-full">
                        <weather-widget></weather-widget>
                    </div>

                    <div class="mx-5 my-3 ">
                        <t-select
                            v-model="selectedAsset"
                            :options="assetList"
                            text-attribute="display_name"
                            value-attribute="name"
                            @change="fetchAssemblyItemFromStore($event)"
                        ></t-select>
                    </div>

                    <!-- <div class=" px-4">
                            <div class="p-1 flex  ">
                                <t-select
                                    v-model="selectedAsset"
                                    :options="assetList"
                                    text-attribute="description"
                                    value-attribute="name"
                                    >Asset List</t-select
                                >
                            </div>
                        </div> -->

                    <div
                        class="flex-1"
                        v-if="
                            selectedAsset != 'Please Select An Asset' &&
                                selectedAsset != null
                        "
                    >
                        <div class="flex h-12 px-4 ">
                            <t-button
                                @click="changePanel('properties')"
                                class="flex-auto my-1 rounded-l"
                                :class="
                                    currentStep === 1
                                        ? 'bg-green-400'
                                        : 'bg-green-600'
                                "
                            >
                                Properties
                            </t-button>
                            <t-button
                                @click="changePanel('status')"
                                class="flex-auto my-1 "
                                :class="
                                    currentStep === 2
                                        ? 'bg-green-400 '
                                        : 'bg-green-600 '
                                "
                            >
                                Status
                            </t-button>
                            <t-button
                                @click="changePanel('mining')"
                                class="flex-auto my-1 "
                                :class="
                                    currentStep === 3
                                        ? 'bg-green-400'
                                        : 'bg-green-600 '
                                "
                            >
                                Mining
                            </t-button>
                            <t-button
                                @click="changePanel('cost')"
                                class="flex-auto my-1 "
                                :class="
                                    currentStep === 4
                                        ? 'bg-green-400'
                                        : 'bg-green-600 '
                                "
                            >
                                Cost
                            </t-button>
                            <t-button
                                @click="changePanel('schedule')"
                                class="flex-auto my-1 "
                                :class="
                                    currentStep === 5
                                        ? 'bg-green-400'
                                        : 'bg-green-600 '
                                "
                            >
                                Schedule
                            </t-button>
                            <t-button
                                @click="changePanel('documents')"
                                class="flex-auto my-1 rounded-r "
                                :class="
                                    currentStep === 6
                                        ? 'bg-green-400'
                                        : 'bg-green-600 '
                                "
                            >
                                Documents
                            </t-button>
                        </div>

                        <div class="mt-2 overflow-y-scroll propertiesSizeCalc ">
                            <div class=" ">
                                <!-- <transition name="slide"> -->
                                <div
                                    v-if="currentStep == 1"
                                    key="1"
                                    class="w-full pl-4"
                                >
                                    <div v-if="this.selectedAsset">
                                        <div class="text-xl   mb-2 flex ">
                                            <font-awesome-icon
                                                class="text-green-600 mr-4 my-2"
                                                :icon="['fal', 'search']"
                                                size="1x"
                                            />
                                            <div class="my-auto ">
                                                Search Filters
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="mx-1 my-1 w-4/6">
                                                <t-input
                                                    class="w-full m-0 p-0"
                                                    v-model="searchTerm"
                                                    placeholder="Asset Search..."
                                                    @keyup.enter="
                                                        fetchAssetsWithSearch
                                                    "
                                                ></t-input>
                                            </div>
                                            <t-button
                                                @click="fetchAssetsWithSearch"
                                                class="w-2/6 flex-auto my-1 mx-1 bg-green-600 hover:bg-green-400 rounded"
                                                >Search</t-button
                                            >
                                        </div>
                                        <div class="my-2">
                                            <input
                                                @change="fetchAssetsWithSearch"
                                                v-model="showConsumable"
                                                type="checkbox"
                                                class="mx-2  align-middle  transition duration-100 ease-in-out rounded shadow-sm  disabled:opacity-50 disabled:cursor-not-allowed text-green-500 border-green-300 "
                                            /><span
                                                >Show only consumable
                                                components</span
                                            >
                                        </div>
                                    </div>

                                    <transition name="fade-out-in">
                                        <div class="px-2 py-4">
                                            <div
                                                class="text-xl mb-4 flex "
                                                v-if="
                                                    assemblyItem.components &&
                                                        assemblyItem.components
                                                            .length > 0
                                                "
                                            >
                                                <font-awesome-icon
                                                    class="text-green-600 mr-4  my-2"
                                                    :icon="['fal', 'industry']"
                                                    size="1x"
                                                />
                                                <div class="my-auto">
                                                    Assembly components
                                                </div>
                                            </div>
                                            <div
                                                v-for="component in assemblyItem.components"
                                                :key="component.guid"
                                            >
                                                <asset-properties
                                                    :displayName="
                                                        component.display_name
                                                    "
                                                    :description="
                                                        component.description
                                                    "
                                                    :serial="
                                                        component.serial_number
                                                    "
                                                    :quantity="
                                                        component.quantity
                                                    "
                                                    :componentType="
                                                        component.type
                                                    "
                                                ></asset-properties>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                                <div
                                    class="w-full pl-4 pb-2"
                                    v-if="currentStep == 2"
                                    key="2"
                                >
                                    <div class="text-xl ml-2 mt-4  mb-3 flex ">
                                        <font-awesome-icon
                                            class="text-green-600 mr-4 my-2"
                                            :icon="['fal', 'wrench']"
                                            size="1x"
                                        />
                                        <div class="my-auto ">
                                            Live Status
                                        </div>
                                    </div>
                                    <div class="mx-2 py-1">
                                        <div
                                            class="  bg-green-50 border-green-200 border rounded-lg p-4"
                                        >
                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .kw
                                                "
                                            >
                                                Kw:
                                                {{ assemblyItem.properties.kw }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .frame
                                                "
                                            >
                                                Frame:
                                                {{
                                                    assemblyItem.properties
                                                        .frame
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .q
                                                "
                                            >
                                                Q:
                                                {{ assemblyItem.properties.q }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .h
                                                "
                                            >
                                                H:
                                                {{ assemblyItem.properties.h }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .pump_speed
                                                "
                                            >
                                                Pump speed:
                                                {{
                                                    assemblyItem.properties
                                                        .pump_speed
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .nom_flow
                                                "
                                            >
                                                Nom flow:
                                                {{
                                                    assemblyItem.properties
                                                        .nom_flow
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .o_flow
                                                "
                                            >
                                                O flow:
                                                {{
                                                    assemblyItem.properties
                                                        .o_flow
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .u_flow
                                                "
                                            >
                                                U flow:
                                                {{
                                                    assemblyItem.properties
                                                        .u_flow
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.properties &&
                                                        assemblyItem.properties
                                                            .motor_speed
                                                "
                                            >
                                                Motor Speed:
                                                {{
                                                    assemblyItem.properties
                                                        .motor_speed
                                                }}
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.name ===
                                                        '36-ML-01'
                                                "
                                            >
                                                <div>
                                                    Oil Flow:
                                                    {{
                                                        statusData.sagMill
                                                            .oilFlow
                                                    }}lpm
                                                </div>
                                                <div>
                                                    Oil Pressure:
                                                    {{
                                                        statusData.sagMill
                                                            .oilPressure
                                                    }}bar
                                                </div>
                                                <div>
                                                    Heater temperature:
                                                    {{
                                                        statusData.sagMill
                                                            .heaterTemperature
                                                    }}C&deg;
                                                </div>
                                                <div>
                                                    Oil Tank Temperature:
                                                    {{
                                                        statusData.sagMill
                                                            .oilTankTemperature
                                                    }}C&deg;
                                                </div>

                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Oil Flow"
                                                        :dataValue="
                                                            statusData.sagMill
                                                                .oilFlow
                                                        "
                                                    ></live-chart>
                                                </div>
                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Oil Pressure"
                                                        :dataValue="
                                                            statusData.sagMill
                                                                .oilPressure
                                                        "
                                                    ></live-chart>
                                                </div>

                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Heater temperature"
                                                        :dataValue="
                                                            statusData.sagMill
                                                                .heaterTemperature
                                                        "
                                                    ></live-chart>
                                                </div>
                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Oil Tank Temperature"
                                                        :dataValue="
                                                            statusData.sagMill
                                                                .oilTankTemperature
                                                        "
                                                    ></live-chart>
                                                </div>
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.name ===
                                                        '36-CV-01'
                                                "
                                            >
                                                <div>
                                                    Temperature:
                                                    {{
                                                        statusData.conveyer
                                                            .temperature
                                                    }}C&deg;
                                                </div>
                                                <div>
                                                    Sensor output callibrated
                                                    speed:
                                                    {{
                                                        statusData.conveyer
                                                            .sensorSpeed
                                                    }}mA
                                                </div>
                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Temperature"
                                                        :dataValue="
                                                            statusData.conveyer
                                                                .temperature
                                                        "
                                                    ></live-chart>
                                                </div>
                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Sensor output callibrated
                                                    speed"
                                                        :dataValue="
                                                            statusData.conveyer
                                                                .sensorSpeed
                                                        "
                                                    ></live-chart>
                                                </div>
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.name ===
                                                        '36-PP-02' ||
                                                        assemblyItem.name ===
                                                            '36-PP-03'
                                                "
                                            >
                                                <div>
                                                    Temperature:
                                                    {{
                                                        statusData.warman
                                                            .temperature
                                                    }}C&deg;
                                                </div>
                                                <div>
                                                    Vibration check:
                                                    {{
                                                        statusData.warman
                                                            .vibration
                                                    }}mm/s
                                                </div>

                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Temperature"
                                                        :dataValue="
                                                            statusData.warman
                                                                .temperature
                                                        "
                                                    ></live-chart>
                                                </div>
                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Vibration"
                                                        :dataValue="
                                                            statusData.warman
                                                                .vibration
                                                        "
                                                    ></live-chart>
                                                </div>
                                            </div>

                                            <div
                                                v-if="
                                                    assemblyItem.name ===
                                                        '36-CY-01'
                                                "
                                            >
                                                <div>
                                                    Temperature:
                                                    {{
                                                        statusData.cyclones
                                                            .temperature
                                                    }}C&deg;
                                                </div>
                                                <div>
                                                    Vibration check:
                                                    {{
                                                        statusData.cyclones
                                                            .vibration
                                                    }}mm/s
                                                </div>
                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Temperature"
                                                        :dataValue="
                                                            statusData.cyclones
                                                                .temperature
                                                        "
                                                    ></live-chart>
                                                </div>
                                                <div>
                                                    <live-chart
                                                        id="liveChart"
                                                        :height="250"
                                                        dataLabel="Vibration"
                                                        :dataValue="
                                                            statusData.cyclones
                                                                .vibration
                                                        "
                                                    ></live-chart>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="w-full pl-4"
                                    v-if="currentStep == 3"
                                    key="3"
                                >
                                    <live-chart
                                        id="liveChart"
                                        :height="250"
                                        dataLabel="Grinding Speed"
                                    ></live-chart>

                                    <live-chart
                                        id="liveChart"
                                        :height="250"
                                        dataLabel="Material Quality"
                                    ></live-chart>
                                </div>
                                <div
                                    class="w-full pl-4"
                                    v-if="currentStep == 4"
                                    key="4"
                                >
                                    <div v-if="costData.length >= 1">
                                        <t-table
                                            :headers="[
                                                'Component',
                                                'Installation Cost',
                                                'Yearly Maintainance Costs',
                                                'Component costs'
                                            ]"
                                            :data="costData"
                                        ></t-table>
                                    </div>
                                    <div v-else>
                                        No cost data found for this assembly.
                                    </div>
                                </div>
                                <div
                                    class="w-full pl-4"
                                    v-if="currentStep == 5"
                                    key="5"
                                >
                                    <div class="text-xl ml-2 mt-4  mb-3 flex ">
                                        <font-awesome-icon
                                            class="text-green-600 mr-4 my-2"
                                            :icon="['fal', 'calendar']"
                                            size="1x"
                                        />
                                        <div class="my-auto ">
                                            Current assembly schedule
                                        </div>
                                    </div>

                                    <div class=" timeline mr-4">
                                        <div
                                            class=""
                                            v-for="item in assemblySchedulesMatched.steps"
                                            :key="item.assemblyGuid"
                                        >
                                            <div class="timestamp ">
                                                <span class="date">{{
                                                    item.dateLabel
                                                }}</span>
                                            </div>
                                            <div
                                                v-if="item.isComplete"
                                                class="status complete"
                                            >
                                                <span class="mx-1"
                                                    >{{
                                                        item.title
                                                    }}
                                                    (Complete)</span
                                                >
                                            </div>
                                            <div v-else class="status">
                                                <span class="mx-6">{{
                                                    item.title
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="w-full  my-2">
                                            <input
                                                type="checkbox"
                                                class="mr-2 align-middle  transition duration-100 ease-in-out rounded shadow-sm  disabled:opacity-50 disabled:cursor-not-allowed text-green-500 border-green-300 "
                                                v-model="
                                                    viewerVisualiseInstalled
                                                "
                                                @change="changeInstalled"
                                            /><span
                                                class="text-xs pa-0 ma-0 align-middle"
                                                >Show all non-installed in 3D
                                                viewer</span
                                            >
                                        </div>
                                    </div>

                                    <div class="text-xl ml-2 mt-4  mb-3 flex ">
                                        <font-awesome-icon
                                            class="text-green-600 mr-4 my-2"
                                            :icon="['fal', 'calendar']"
                                            size="1x"
                                        />
                                        <div class="my-auto ">
                                            Upcoming maintainance
                                        </div>
                                    </div>
                                    <div
                                        v-if="assemblyItem.name === '36-ML-01'"
                                    >
                                        <t-table
                                            :headers="[
                                                'Due Date',
                                                'Maintainance Description'
                                            ]"
                                            :data="maintainanceData.sagmill"
                                        ></t-table>
                                    </div>
                                    <div
                                        v-if="assemblyItem.name === '36-CY-01'"
                                    >
                                        <t-table
                                            :headers="[
                                                'Due Date',
                                                'Maintainance Description'
                                            ]"
                                            :data="maintainanceData.cyclones"
                                        ></t-table>
                                    </div>
                                    <div
                                        v-if="assemblyItem.name === '36-CV-01'"
                                    >
                                        <t-table
                                            :headers="[
                                                'Due Date',
                                                'Maintainance Description'
                                            ]"
                                            :data="maintainanceData.conveyer"
                                        ></t-table>
                                    </div>

                                    <div
                                        v-if="
                                            assemblyItem.name === '36-PP-02' ||
                                                assemblyItem.name === '36-PP-03'
                                        "
                                    >
                                        <t-table
                                            :headers="[
                                                'Due Date',
                                                'Maintainance Description'
                                            ]"
                                            :data="maintainanceData.warman"
                                        ></t-table>
                                    </div>
                                </div>
                                <div
                                    class="w-full pl-4"
                                    v-if="currentStep == 6"
                                    key="6"
                                >
                                    <div
                                        v-if="
                                            assemblyItem.documents &&
                                                assemblyItem.documents.length >
                                                    0
                                        "
                                    >
                                        <div>
                                            <div class="text-xl  mt-2 flex ">
                                                <font-awesome-icon
                                                    class="text-green-600 mr-4 my-2"
                                                    :icon="['fal', 'search']"
                                                    size="1x"
                                                />
                                                <div class="my-auto ">
                                                    Search Filters
                                                </div>
                                            </div>
                                            <div class="flex ">
                                                <div class="flex-1 m-2 py-1">
                                                    <span>Type:</span>
                                                    <t-select
                                                        class="my-1"
                                                        v-model="
                                                            selectedFilterCategory
                                                        "
                                                        :options="
                                                            categoryFilterList
                                                        "
                                                        text-attribute="name"
                                                        value-attribute="code"
                                                        @change="filtersUpdate"
                                                    ></t-select>
                                                </div>
                                                <div class="flex-1 m-2 py-1">
                                                    <span>Originator:</span>
                                                    <t-select
                                                        class="my-1"
                                                        v-model="
                                                            selectedFilterOriginator
                                                        "
                                                        :options="
                                                            originatorFilterList
                                                        "
                                                        text-attribute="name"
                                                        value-attribute="code"
                                                        @change="filtersUpdate"
                                                    ></t-select>
                                                </div>
                                                <div class="flex-1 m-2 py-1">
                                                    <span>Discipline:</span>
                                                    <t-select
                                                        class="my-1"
                                                        v-model="
                                                            selectedFilterDiscipline
                                                        "
                                                        :options="
                                                            disciplineFilterList
                                                        "
                                                        text-attribute="name"
                                                        value-attribute="code"
                                                        @change="filtersUpdate"
                                                    ></t-select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-xl  mt-4 mb-4 flex ">
                                            <font-awesome-icon
                                                class="text-green-600 mr-4 my-2"
                                                :icon="['fal', 'file']"
                                                size="1x"
                                            />
                                            <div class="my-auto ">
                                                Documents
                                            </div>
                                        </div>

                                        <div
                                            class="mr-4"
                                            v-for="document in documents"
                                            :key="document.guid"
                                        >
                                            <document
                                                :document="document"
                                            ></document>

                                            <!--  :categoryFilter="
                                                    selectedFilterCategory
                                                "
                                                :originatorFilter="
                                                    selectedFilterOriginator
                                                "
                                                :disciplineFilter="
                                                    selectedFilterDiscipline
                                                "
                                                @filterChanged="
                                                    filtersUpdate(document)
                                                " -->
                                        </div>
                                    </div>
                                    <div v-else>
                                        No documents found.
                                    </div>
                                </div>
                                <!-- </transition> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.home {
    height: calc(100% - 65px);
}

.homeScr {
    height: calc(100vh - 65px);
}

.propertiesSizeCalc {
    height: calc(100vh - 20rem);
}

.timeline {
    width: auto;
    margin: 25px 0;
    list-style-type: none;
    display: flex;
    padding: 0;
    text-align: center;
}

.timestamp {
    width: auto;
    margin-bottom: 20px;
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
    font-size: 14px;
}
.status {
    padding: 0px 0px;
    font-size: small;
    display: flex;
    justify-content: center;
    border-top: 4px solid rgb(5, 150, 105);
    position: relative;
}

.status span {
    font-weight: 400;
    padding-top: 12px;
}
.status span:before {
    content: "";
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 25px;
    border: 4px solid rgb(5, 150, 105);
    position: absolute;
    top: -15px;
    left: 40%;
}

.complete span:before {
    color: white;
    text-align: center;
    vertical-align: middle;
    content: "";
    width: 25px;
    height: 25px;

    border-radius: 25px;

    position: absolute;
    top: -15px;
    left: 40%;

    background-color: rgb(5, 150, 105);
    /* background-color: rgb(110, 231, 183); */
    border: 4px solid rgb(5, 150, 105);
}
</style>

<style>
/* Fade in and out transitions */

.fade-out-in-enter-active,
.fade-out-in-leave-active {
    transition: opacity 0.5s;
}

.fade-out-in-enter-active {
    transition-delay: 0.5s;
}

.fade-out-in-enter,
.fade-out-in-leave-to {
    opacity: 0;
}

/* Slide Transitions */

.slide-leave-active,
.slide-enter-active {
    transition: 1s;
    transition-property: height, opacity, transform;
}
.slide-enter {
    transform: translate(100%, 0);
}
.slide-leave-to {
    transform: translate(100%, 0);
    opacity: 0;
}
</style>

<script>
// @ is an alias to /src
import ForgeViewer from "@/forge/ForgeViewer.vue";
// import ForgeHotSpot from "@/forge/ForgeHotSpot.vue";
import AssetProperties from "../components/Sidepanel/AssetProperties.vue";
import WeatherWidget from "../components/Sidepanel/WeatherWidget.vue";
import Document from "../components/Sidepanel/Document.vue";
import LiveChart from "../components/ChartComponents/LiveChart.vue";
import Cookies from "js-cookie";
// import DataFeed from "../components/Sidepanel/DataFeed.vue";

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "Home",
    data() {
        return {
            totalSteps: 6,
            viewerVisualiseInstalled: false,
            searchTerm: "",
            showConsumable: false,
            currentStep: 1,
            selectedPanel: {
                assets: true,
                maintainance: false,
                documents: false
            },
            selectedAsset: null,
            selectedFilterCategory: null,
            selectedFilterDiscipline: null,
            selectedFilterOriginator: null,
            assetList: [
                // "Sag mill",
                // "Conveyor belt",
                // "Cyclone",
                // "Pump Warman #1",
                // "Pump Warman #2",
            ],
            categoryFilterList: [],
            originatorFilterList: [],
            disciplineFilterList: [],
            feedData: {
                type: "line",
                data: {
                    labels: [
                        "00:00",
                        "01:00",
                        "02:00",
                        "03:00",
                        "04:00",
                        "05:00",
                        "06:00",
                        "07:00",
                        "08:00",
                        "09:00",
                        "10:00",
                        "11:00"
                    ],
                    datasets: [
                        {
                            label: "Temperature",
                            data: [
                                19.1,
                                19.1,
                                19,
                                19.2,
                                19.7,
                                19.8,
                                19.9,
                                20,
                                20.2,
                                20.1,
                                20.1,
                                20.4
                            ]
                        }
                    ]
                },
                options: {
                    title: {
                        display: true,
                        text: "Temperature"
                    }
                }
            },
            steps: [
                {
                    dateLabel: null,
                    // .subtract(80, "days")

                    title: "Date of delivery",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    // .subtract(7, "days")

                    title: "Date of installation",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    title: "Date of commisioning",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    // .add(56, "days")

                    title: "Date of operation",
                    isComplete: false
                }
            ],
            assemblySchedules: [],
            costData: [],
            maintainanceData: {
                sagmill: [
                    [
                        this.moment(Date.now()).format("DD/MM/YYYY"),
                        "Check for leaks on all lubrication systems connections"
                    ],
                    [
                        this.moment(Date.now()).format("DD/MM/YYYY"),
                        "Check clogging indicators in all filters. Change filters if necessary."
                    ],
                    [
                        this.moment(Date.now()).format("DD/MM/YYYY"),
                        "Check trunnion contact face for wear and scuff marks."
                    ],
                    [
                        this.moment(Date.now())
                            .add(43, "days")
                            .format("DD/MM/YYYY"),
                        "Check the oil level in inching gearbox."
                    ],
                    [
                        this.moment(Date.now())
                            .add(87, "days")
                            .format("DD/MM/YYYY"),
                        "Check mill seals for damage. Any damaged seal should be replaced immediately"
                    ],
                    [
                        this.moment(Date.now())
                            .add(118, "days")
                            .format("DD/MM/YYYY"),
                        "Oil change in gearbox lubrication unit. Oil type ISO VG 220 mineral oil. QTY= 1500lt."
                    ]
                ],
                conveyer: [
                    [
                        this.moment(Date.now()).format("DD/MM/YYYY"),
                        "Check and adjust conveying belt for correct tension and alignment."
                    ],
                    [
                        this.moment(Date.now()).format("DD/MM/YYYY"),
                        "Verify that all idlers are rotating and in contact with the belt."
                    ],
                    [
                        this.moment(Date.now())
                            .add(112, "days")
                            .format("DD/MM/YYYY"),
                        "Greasing heavy duty  tail pulley bearings. Grease type EP2. 27 gr/greasing point"
                    ],
                    [
                        this.moment(Date.now())
                            .add(142, "days")
                            .format("DD/MM/YYYY"),
                        "Greasing heavy duty  head  pulley bearings. Grease type EP2.  41.4 gr/greasing point"
                    ],
                    [
                        this.moment(Date.now())
                            .add(152, "days")
                            .format("DD/MM/YYYY"),
                        "Greasing belt beater shaft   bearings. Grease type EP2.  20.15 gr/greasing point"
                    ],
                    [
                        this.moment(Date.now())
                            .add(684, "days")
                            .format("DD/MM/YYYY"),
                        "Oil change in the gearbox. Oil type ISO VG CLP 220. QTY= 3.7lt."
                    ]
                ],
                cyclones: [
                    [
                        this.moment(Date.now())
                            .add(2, "days")
                            .format("DD/MM/YYYY"),
                        "Valves grease lubrication. Greare type DOW 111. For 10''  113 cm3/greasing point. "
                    ],
                    [
                        this.moment(Date.now())
                            .add(5, "days")
                            .format("DD/MM/YYYY"),
                        "Valves grease lubrication. Greare type DOW 111. For  16''  267 cm3/greasing point. "
                    ],
                    [
                        this.moment(Date.now())
                            .add(76, "days")
                            .format("DD/MM/YYYY"),
                        "Inspect cyclone liners."
                    ],
                    [
                        this.moment(Date.now())
                            .add(158, "days")
                            .format("DD/MM/YYYY"),
                        "Check the hydrocyclone distributor for exessive wear and corrosion."
                    ]
                ],
                warman: [
                    [
                        this.moment(Date.now())
                            .add(3, "days")
                            .format("DD/MM/YYYY"),
                        "Greasing labyrinth seals. Grease type EP2. 4gr/greasing point"
                    ],
                    [
                        this.moment(Date.now())
                            .add(240, "days")
                            .format("DD/MM/YYYY"),
                        "Greasing bearings. Grease type EP2. Motor side 60gr. Pump side 85gr."
                    ]
                ]
            },
            statusData: {
                sagMill: {
                    oilFlow: 0,
                    oilPressure: 0,
                    heaterTemperature: 0,
                    oilTankTemperature: 0
                },
                cyclones: { vibration: 0, temperature: 0 },
                conveyer: { temperature: 0, sensorSpeed: 0 },
                warman: { vibration: 0, temperature: 0 }
            }
        };
    },
    watch: {},
    components: {
        ForgeViewer,
        // ForgeHotSpot,
        AssetProperties,
        WeatherWidget,
        // DataFeed,
        Document,

        LiveChart
    },
    computed: {
        ...mapState({
            assets: state => state.assets.assembilies,
            assemblyItem: state => state.assets.assemblyItem,
            viewables: state => state.assets.viewables,
            viewer: state => state.viewer.viewer,
            documents: state => state.documents.documents,
            documentFilters: state => state.documents.filters,
            assetComponents: state => state.components.components,
            token: state => state.account.token
        }),

        ...mapGetters({
            getExtensionById: "viewer/getExtensionById"
        }),

        assemblySchedulesMatched() {
            return this.assemblySchedules.find(
                s => s.assemblyName === this.selectedAsset
            );
        }
    },
    async mounted() {
        await this.fetchAssets();
        await this.fetchDocumentFilters();
        this.assetList.push("Please Select An Asset");
        this.assets.forEach(obj => {
            this.assetList.push(obj);
        });
        this.categoryFilterList.push({ code: "", name: "Please Select" });
        this.documentFilters.type.filters.forEach(obj => {
            this.categoryFilterList.push(obj);
        });
        this.originatorFilterList.push({ code: "", name: "Please Select" });
        this.documentFilters.originator.filters.forEach(obj => {
            this.originatorFilterList.push(obj);
        });
        this.disciplineFilterList.push({ code: "", name: "Please Select" });
        this.documentFilters.discipline.filters.forEach(obj => {
            this.disciplineFilterList.push(obj);
        });
        this.buildLiveStatusData();

        if (
            !this.token ||
            this.token.accessToken === undefined ||
            this.token.accessToken === null ||
            Cookies.get("refreshToken") === null ||
            Cookies.get("Bearer") === null
        ) {
            this.$router.push("/login");
        }

        // for (let i in this.assets) {
        //     const steps = this.RandomScheduleGenerator();
        //     this.assemblySchedules.push({
        //         steps: steps,
        //         assemblyGuid: this.assets[i].guid,
        //         assemblyName: this.assets[i].name
        //     });
        // }

        for (let i in this.assets) {
            let steps = [
                {
                    dateLabel: null,
                    // .subtract(80, "days")

                    title: "Date of delivery",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    // .subtract(7, "days")

                    title: "Date of installation",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    title: "Date of commisioning",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    // .add(56, "days")

                    title: "Date of operation",
                    isComplete: false
                }
            ];

            steps[0].dateLabel = this.moment(
                this.assets[i].delivery_date
            ).format("DD/MM/YYYY");

            if (this.moment(steps[0].dateLabel) < this.moment(Date.now()))
                steps[0].isComplete = true;
            else steps[0].isComplete = false;

            steps[1].dateLabel = this.moment(
                this.assets[i].installation_date
            ).format("DD/MM/YYYY");

            if (this.moment(steps[1].dateLabel) < this.moment(Date.now()))
                steps[1].isComplete = true;
            else steps[1].isComplete = false;

            steps[2].dateLabel = this.moment(
                this.assets[i].commissioning_date
            ).format("DD/MM/YYYY");

            if (this.moment(steps[2].dateLabel) < this.moment(Date.now()))
                steps[2].isComplete = true;
            else steps[2].isComplete = false;

            steps[3].dateLabel = this.moment(
                this.assets[i].operation_date
            ).format("DD/MM/YYYY");

            if (this.moment(steps[3].dateLabel) < this.moment(Date.now()))
                steps[3].isComplete = true;
            else steps[3].isComplete = false;

            this.assemblySchedules.push({
                steps: steps,
                assemblyGuid: this.assets[i].guid,
                assemblyName: this.assets[i].name
            });
        }

        this.RandomCostGenerator();
    },
    methods: {
        ...mapActions({
            fetchAssets: "assets/fetchAssembilies",
            fetchAssemblyItem: "assets/fetchAssemblyItem",
            fetchViewables: "assets/fetchViewables",
            fetchDocuments: "documents/fetchDocuments",
            fetchDocumentFilters: "documents/fetchDocumentFilters"
        }),
        ...mapMutations({
            setToken: "account/setToken"
        }),
        async logout() {
            await this.setToken({ tokenType: null, accessToken: null });
            Cookies.remove("bearer");
            this.$router.push("/login");
        },

        async changeInstalled() {
            while (
                !(await this.getExtensionById("BIMAcademy.ColourObjects")
                    .instance)
            ) {
                await new Promise(r => setTimeout(r, 500));
            }

            var instanceTree = this.viewer.model.getData().instanceTree;
            var rootId = instanceTree.getRootId();
            await this.fetchViewables();

            if (this.viewerVisualiseInstalled) {
                this.viewer.hide(rootId); // hiding root node will hide whole model ...

                let viewablesToShow = [];
                for (let i in this.viewables) {
                    const foundAssembly = this.assemblySchedules.find(
                        f => f.assemblyGuid === this.viewables[i].assembly.guid
                    );
                    if (foundAssembly) {
                        if (foundAssembly.steps[1].isComplete === false)
                            viewablesToShow.push(this.viewables[i].geometry);
                    }
                }

                this.viewer.show(viewablesToShow);
            } else this.viewer.show(rootId);
        },

        RandomScheduleGenerator() {
            let dateBuilder = Date.now();
            let steps = [
                {
                    dateLabel: null,
                    // .subtract(80, "days")

                    title: "Date of delivery",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    // .subtract(7, "days")

                    title: "Date of installation",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    title: "Date of commisioning",
                    isComplete: true
                },
                {
                    dateLabel: null,
                    // .add(56, "days")

                    title: "Date of operation",
                    isComplete: false
                }
            ];

            dateBuilder = this.moment(dateBuilder).subtract(
                this.getRndInteger(18, 42),
                "days"
            );

            //Date of delivery
            steps[0].dateLabel = this.moment(dateBuilder).format("DD/MM/YYYY");

            if (dateBuilder < Date.now()) steps[0].isComplete = true;
            else steps[0].isComplete = false;

            dateBuilder = this.moment(dateBuilder).add(
                this.getRndInteger(22, 42),
                "days"
            );

            //Date of installation
            steps[1].dateLabel = this.moment(dateBuilder).format("DD/MM/YYYY");

            if (dateBuilder < Date.now()) steps[1].isComplete = true;
            else steps[1].isComplete = false;

            dateBuilder = this.moment(dateBuilder).add(
                this.getRndInteger(5, 10),
                "days"
            );

            //Date of commisioning
            steps[2].dateLabel = this.moment(dateBuilder).format("DD/MM/YYYY");

            if (dateBuilder < Date.now()) steps[2].isComplete = true;
            else steps[2].isComplete = false;

            dateBuilder = this.moment(dateBuilder).add(
                this.getRndInteger(5, 14),
                "days"
            );

            //Date of operation
            steps[3].dateLabel = this.moment(dateBuilder).format("DD/MM/YYYY");

            if (dateBuilder < Date.now()) steps[3].isComplete = true;
            else steps[3].isComplete = false;
            return steps;
        },

        getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },

        getRndFloat(min, max, decimals) {
            return (Math.random() * (max - min) + min).toFixed(decimals);
        },

        getRndMining() {
            return Math.random();
        },

        buildLiveStatusData() {
            // sagmill

            // warman: { vibration: 0, temperature: 0 }
            setInterval(() => {
                this.statusData.sagMill.oilFlow = this.getRndInteger(200, 220);
                this.statusData.sagMill.oilPressure = this.getRndFloat(
                    1.5,
                    2.0,
                    2
                );
                this.statusData.sagMill.heaterTemperature = this.getRndFloat(
                    40,
                    50,
                    1
                );
                this.statusData.sagMill.oilTankTemperature = this.getRndFloat(
                    30,
                    35,
                    1
                );
            }, 1000);

            //cyclones
            setInterval(() => {
                this.statusData.cyclones.vibration = this.getRndFloat(
                    6,
                    7.5,
                    1
                );
                this.statusData.cyclones.temperature = this.getRndFloat(
                    80,
                    95,
                    1
                );
            }, 1000);

            //conveyer
            setInterval(() => {
                this.statusData.conveyer.sensorSpeed = this.getRndInteger(
                    17,
                    20
                );
                this.statusData.conveyer.temperature = this.getRndFloat(
                    80,
                    90,
                    1
                );
            }, 1000);

            //warman
            setInterval(() => {
                this.statusData.warman.vibration = this.getRndFloat(6, 7.5, 1);
                this.statusData.warman.temperature = this.getRndFloat(
                    80,
                    95,
                    1
                );
            }, 1000);
        },

        getHighRandomPrice() {
            const hundreds = this.getRndInteger(0, 4000);
            const tens = this.getRndInteger(99, 999);
            let units = this.getRndInteger(0, 99);

            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "EUR"
            });

            return formatter.format(hundreds + tens + units);
        },

        getLowRandomPrice() {
            const tens = this.getRndInteger(0, 100);
            let units = this.getRndInteger(0, 99);
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "EUR"
            });

            return formatter.format(tens + units);
        },

        getMidRandomPrice() {
            const tens = this.getRndInteger(0, 999);
            let units = this.getRndInteger(0, 99);

            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "EUR"
            });

            return formatter.format(tens + units);
        },

        RandomCostGenerator() {
            this.costData = [];
            this.assemblyItem.components?.forEach(element => {
                this.costData.push([
                    element.name,
                    `${this.getHighRandomPrice()}`, // install cost
                    `${this.getLowRandomPrice()}`, // maintainance cost
                    `${this.getMidRandomPrice()}` // component cost
                ]);
            });
        },

        async fetchAssetsWithSearch() {
            const assetGuid = this.assetList.find(
                x => x.name === this.selectedAsset
            ).guid;

            let builtObject = {};
            builtObject.guid = assetGuid;

            if (this.showConsumable === true) builtObject.is_consumable = true;
            if (this.searchTerm != "") builtObject.search = this.searchTerm;

            await this.fetchAssemblyItem(
                //filter(this.showConsumable, this.searchTerm, assetGuid)
                builtObject
            );
        },

        setCamera(pointsForFocus, cameraPosition) {
            // get the zoom closer
            this.viewer.navigation.FIT_TO_VIEW_HORIZONTAL_MARGIN = -0.9;
            this.viewer.navigation.FIT_TO_VIEW_VERTICAL_MARGIN = -0.15;

            this.viewer.navigation.setView(cameraPosition, pointsForFocus);

            this.viewer.navigation.setCameraUpVector({ x: 0, y: 0, z: 90 });
            this.viewer.fitToView(pointsForFocus);
        },

        async fetchAssemblyItemFromStore(event) {
            if (!event?.type) {
                const assembly = this.assetList.find(
                    element => element.name === this.selectedAsset
                );

                await this.fetchAssemblyItem({
                    guid: assembly.guid,
                    search: ""
                });
                await this.fetchViewables();

                let viewable = this.viewables.filter(obj => {
                    return obj.assembly.guid === assembly.guid;
                });

                this.setCamera(
                    viewable[0].camera.focus,
                    viewable[0].camera.position
                );

                this.fetchDocuments({ assembly: assembly.guid });
                this.RandomCostGenerator();
                this.viewerVisualiseInstalled = false;
                this.changeInstalled();
            }
        },

        filtersUpdate(event) {
            if (!event?.type) {
                const assetGuid = this.assetList.find(
                    x => x.name === this.selectedAsset
                ).guid;

                let fetchBuilder = {
                    assembly: assetGuid
                };

                if (
                    this.selectedFilterCategory &&
                    this.selectedFilterCategory != ""
                )
                    fetchBuilder.type = this.selectedFilterCategory;

                if (
                    this.selectedFilterOriginator &&
                    this.selectedFilterOriginator != ""
                )
                    fetchBuilder.originator = this.selectedFilterOriginator;

                if (
                    this.selectedFilterDiscipline &&
                    this.selectedFilterDiscipline != ""
                )
                    fetchBuilder.discipline = this.selectedFilterDiscipline;

                this.fetchDocuments(fetchBuilder);
            }
        },

        changePanel(selectedPanel) {
            if (selectedPanel == "properties") {
                this.currentStep = 1;
            }
            if (selectedPanel == "status") {
                this.currentStep = 2;
            }
            if (selectedPanel == "mining") {
                this.currentStep = 3;
            }
            if (selectedPanel == "cost") {
                this.currentStep = 4;
            }
            if (selectedPanel == "schedule") {
                this.currentStep = 5;
            }
            if (selectedPanel == "documents") {
                this.currentStep = 6;
            }
        }
    }
};
</script>
