import axios from "axios";

function getApiURL(path) {
    return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
    namespaced: true,
    state: {
        components: {}
    },
    getters: {},
    mutations: {
        setComponents(state, components) {
            state.components = components;
        }
    },
    actions: {
        async fetchComponents({ commit }) {
            await axios
                .get(getApiURL("api/components/list"))
                .then(response => {
                    commit("setComponents", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        }
    }
};
