import axios from "axios";
import Vue from "vue";

function getApiURL(path) {
    return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
    namespaced: true,
    state: {
        documents: {},
        filters: {}
    },
    getters: {},
    mutations: {
        setDocuments(state, documents) {
            // state.documents = documents;
            Vue.set(state, "documents", documents);
        },
        setDocumentFilters(state, filters) {
            state.filters = filters;
        }
    },
    actions: {
        async fetchDocuments({ commit }, payload) {
            await axios
                .get(getApiURL("api/documents/list"), {
                    params: payload
                })
                .then(response => {
                    commit("setDocuments", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        },
        async fetchDocumentFilters({ commit }) {
            await axios
                .get(getApiURL("api/documents/filters"))
                .then(response => {
                    commit("setDocumentFilters", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        }
    }
};
