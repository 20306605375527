<template>
    <div>
        <div
            v-if="openSchedulePanel"
            @click="openSchedulePanel = false"
            class="fixed inset-0 h-full w-full z-10 bg-gray-900 opacity-70"
        ></div>
        <span
            @click="openSchedulePanel = !openSchedulePanel"
            class="relative inline-block "
        >
            <font-awesome-icon
                class="hover:text-green-600 mx-4 my-2"
                :class="openSchedulePanel ? 'text-green-600' : 'text-gray-900'"
                :icon="['fal', 'calendar']"
                size="1x"
                alt="Open Alerts Panel"
            />
        </span>

        <div
            v-if="openSchedulePanel"
            class="absolute top-50 left-50 -ml-98 -mt-96 inline-block w-120 h-120 bg-white z-50 border border-gray-200 rounded-lg opacity-95 p-4"
        >
            <div class="m-5">
                <t-select
                    v-model="scheduleData"
                    :options="assetList"
                    text-attribute="display_name"
                    value-attribute="name"
                ></t-select>
                <!-- <t-button @click="turnOffNodes"
                            >turn off nodes</t-button
                        > -->
            </div>

            <div class=" flex-col h-10 overflow-auto">
                <div class="text-center mt-2">
                    Select an assembly to see all scheduled maintanence
                </div>
            </div>
            <div class="m-5">
                <div class=" flex-col h-112 overflow-auto">
                    <t-table
                        :headers="['Frequency', 'Description']"
                        :data="getData"
                    ></t-table>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
/* Fade in and out transitions */

.fade-out-in-enter-active,
.fade-out-in-leave-active {
    transition: opacity 0.3s;
}

.fade-out-in-enter-active {
    transition-delay: 0.3s;
}

.fade-out-in-enter,
.fade-out-in-leave-to {
    opacity: 0;
}
</style>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
    data() {
        return {
            openSchedulePanel: false,
            scheduleData: "36-ML-01",
            selectedAsset: null,
            assetList: [
                // "Sag mill",
                // "Conveyor belt",
                // "Cyclone",
                // "Pump Warman #1",
                // "Pump Warman #2",
            ],
            randomiseId: "36-ML-01",
            assemblySchedules: [
                {
                    id: "36-ML-01",
                    data: [
                        [
                            "Every Day",
                            "Check for leaks on all lubrication systems connections"
                        ],
                        [
                            "Every Day",
                            "Check clogging indicators in all filters. Change filters if necessary."
                        ],
                        [
                            "Every Month",
                            "Check trunnion contact face for wear and scuff marks."
                        ],
                        [
                            "Every Month",
                            "Check the oil level in inching gearbox."
                        ],
                        [
                            "Every 6 Months",
                            "Check mill seals for damage. Any damaged seal should be replaced immediately"
                        ],
                        [
                            "Every 8000 Hours",
                            "Oil change in gearbox lubrication unit. Oil type ISO VG 220 mineral oil. QTY= 1500lt."
                        ]
                    ]
                },
                {
                    id: "36-CY-01",
                    data: [
                        [
                            "Every 50 Cycles",
                            "Valves grease lubrication. Greare type DOW 111. For 10''  113 cm3/greasing point. "
                        ],
                        [
                            "Every 50 Cycles",
                            "Valves grease lubrication. Greare type DOW 111. For  16''  267 cm3/greasing point. "
                        ],
                        ["Every 3 Months", "Inspect cyclone liners."],

                        [
                            "Every 6 Months",
                            "Check the hydrocyclone distributor for exessive wear and corrosion."
                        ]
                    ]
                },
                {
                    id: "36-PP-02",
                    data: [
                        ["Every Day", "Check temperature. Limit 95°"],
                        [
                            "Every Week",
                            "Greasing labyrinth seals. Grease type EP2. 4gr/greasing point"
                        ],
                        [
                            "Every 6860 hours",
                            "Greasing bearings. Grease type EP2. Motor side 60gr. Pump side 85gr."
                        ]
                    ]
                },
                {
                    id: "36-PP-03",
                    data: [
                        ["Every Day", "Check temperature. Limit 95°"],
                        [
                            "Every Week",
                            "Greasing labyrinth seals. Grease type EP2. 4gr/greasing point"
                        ],
                        [
                            "Every 6860 hours",
                            "Greasing bearings. Grease type EP2. Motor side 60gr. Pump side 85gr."
                        ]
                    ]
                },
                {
                    id: "36-CV-01",
                    data: [
                        [
                            "Every 24 Hours",
                            "Check and adjust conveying belt for correct tension and alignment."
                        ],
                        [
                            "Every 24 Hours",
                            "Verify that all idlers are rotating and in contact with the belt."
                        ],
                        [
                            "Every 10000 hours",
                            "Greasing heavy duty  tail pulley bearings. Grease type EP2. 27 gr/greasing point"
                        ],
                        [
                            "Every 10000 hours",
                            "Greasing heavy duty  head  pulley bearings. Grease type EP2.  41.4 gr/greasing point"
                        ],
                        [
                            "Every 10000 hours",
                            "Greasing belt beater shaft   bearings. Grease type EP2.  20.15 gr/greasing point"
                        ],
                        [
                            "Every 3 Years",
                            "Oil change in the gearbox. Oil type ISO VG CLP 220. QTY= 3.7lt."
                        ]
                    ]
                }
            ]
        };
    },
    components: {},
    computed: {
        ...mapState({
            alerts: state => state.alerts.alerts,
            historicalAlerts: state => state.alerts.historicalAlerts,
            viewables: state => state.assets.viewables,
            assets: state => state.assets.assembilies
        }),
        ...mapGetters({
            getExtensionById: "viewer/getExtensionById"
        }),

        getData() {
            const data = this.assemblySchedules.find(
                f => f.id === this.scheduleData
            ).data;

            return data;
        }
    },
    async mounted() {
        await this.fetchAssets();

        this.assets.forEach(obj => {
            this.assetList.push(obj);
        });
    },
    methods: {
        ...mapActions({
            fetchAssets: "assets/fetchAssembilies"
        })
    }
};
</script>
