import axios from "axios";

function getApiURL(path) {
    return process.env.VUE_APP_API_BASE_URL + path;
}

export default {
    namespaced: true,
    state: {
        weather: {}
    },
    getters: {},
    mutations: {
        setWeather(state, weather) {
            state.weather = weather;
        }
    },
    actions: {
        async fetchWeather({ commit }) {
            await axios
                .get(getApiURL("api/weather/current"))
                .then(response => {
                    commit("setWeather", response.data);
                })
                .catch(error => {
                    console.error(error);
                    // commit("setError", error.response);
                });
        }
    }
};
