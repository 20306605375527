<template>
    <div>
        <div
            v-if="alertDropdownOpen"
            @click="alertDropdownOpen = false"
            class="fixed inset-0 h-full w-full z-10"
        ></div>
        <span
            @click="alertDropdownOpen = !alertDropdownOpen"
            class="relative inline-block "
        >
            <font-awesome-icon
                class="hover:text-green-600 mx-4 my-2"
                :class="alertDropdownOpen ? 'text-green-600' : 'text-gray-900'"
                :icon="['fal', 'bell']"
                size="1x"
                alt="Open Alerts Panel"
            />
            <span
                v-if="alerts.notifications && alerts.notifications.length > 0"
                class="absolute top-2 right-3 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
            >
            </span>
        </span>

        <div
            v-if="alertDropdownOpen"
            class="absolute top-20 right-14 inline-block w-1/3  bg-white z-50 border border-gray-200 rounded-lg opacity-95"
        >
            <div class="flex m-2  ">
                <div class="w-full mx-4 my-2">
                    <input
                        type="checkbox"
                        class="mr-2 align-middle  transition duration-100 ease-in-out rounded shadow-sm  disabled:opacity-50 disabled:cursor-not-allowed text-green-500 border-green-300 "
                        v-model="viewerVisualiseColours"
                        @change="colourRoom"
                    /><span class="text-xs pa-0 ma-0 align-middle"
                        >Show last status in 3D viewer</span
                    >
                </div>
                <div class="w-11/12 mx-4 my-2">
                    <!-- LOSING REACTIVITY WITH THIS, BUT WHY?!-->
                    <t-select
                        placeholder="All assets"
                        v-model="selectedAsset"
                        :options="assetList"
                        text-attribute="display_name"
                        value-attribute="guid"
                        @change="fetchAlertsFromStore($event)"
                    ></t-select>
                    <!-- LOSING REACTIVITY WITH THIS, BUT WHY?!-->
                </div>

                <button
                    @click="dropdownOpen = !dropdownOpen"
                    class=" justify-end focus:outline-none "
                >
                    <font-awesome-icon
                        class="text-black hover:text-green-600 mx-4 my-2"
                        :icon="['fa', 'ellipsis-v']"
                        size="1x"
                    />
                </button>
                <div
                    v-if="dropdownOpen"
                    @click="dropdownOpen = false"
                    class="fixed inset-0 h-full w-full z-10"
                ></div>
                <div
                    v-if="dropdownOpen"
                    class="absolute right-4 mt-8 w-48 bg-white rounded-md overflow-hidden shadow-xl z-20 border border-grey-800"
                >
                    <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-800 border-b hover:bg-green-50"
                        @click.prevent="
                            openHistoricalPanel = !openHistoricalPanel;
                            dropdownOpen = !dropdownOpen;
                            alertDropdownOpen = !alertDropdownOpen;
                        "
                        >View Historical
                    </a>
                    <a
                        href="#"
                        @click.prevent="markAllRead"
                        class="block px-4 py-2 text-sm text-gray-800 border-b hover:bg-green-50"
                        >Mark All Read
                    </a>
                </div>
            </div>

            <div class=" flex-col h-96 overflow-auto">
                <div
                    v-for="notification in alerts.notifications"
                    :key="notification.index"
                >
                    <alert-widget
                        :data="notification"
                        @updateAlerts="fetchAlertsFromStore"
                    ></alert-widget>
                </div>
                <div v-if="alerts.notifications.length === 0">
                    <div class="text-center mt-4">
                        There are no notifications!
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="openHistoricalPanel"
            @click="openHistoricalPanel = false"
            class="fixed inset-0 h-full w-full z-10 bg-gray-900 opacity-70"
        ></div>

        <div
            v-if="openHistoricalPanel"
            class="absolute top-50 left-50 -ml-98 -mt-96 inline-block w-120 h-120 bg-white z-50 border border-gray-200 rounded-lg opacity-95 p-4"
        >
            <div class="m-5">
                <t-select
                    v-model="selectedHistoricalAsset"
                    :options="assetList"
                    text-attribute="display_name"
                    value-attribute="guid"
                    @change="fetchHistoricalAlertsFromStore($event)"
                ></t-select>
                <!-- <t-button @click="turnOffNodes"
                            >turn off nodes</t-button
                        > -->
            </div>
            <div class="m-5">
                <div v-if="!selectedHistoricalAsset">
                    Please select an asset to see historical notifications for
                    it...
                </div>
                <div v-if="selectedHistoricalAsset">
                    Showing historical alerts for
                    <span>
                        {{
                            assetList[
                                assetList.indexOf(
                                    assetList.find(
                                        x => x.guid === selectedHistoricalAsset
                                    )
                                )
                            ].display_name
                        }}
                    </span>
                </div>
            </div>
            <div class=" flex-col h-112 overflow-auto">
                <div
                    v-for="notification in historicalAlerts.notifications"
                    :key="notification.index"
                >
                    <alert-widget
                        :data="notification"
                        :showMarkRead="false"
                    ></alert-widget>
                </div>
                <div
                    v-if="
                        historicalAlerts.notifications &&
                            historicalAlerts.notifications.length === 0 &&
                            selectedHistoricalAsset
                    "
                >
                    <div class="text-center mt-4">
                        There are no historical notifications for this asset!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
/* Fade in and out transitions */

.fade-out-in-enter-active,
.fade-out-in-leave-active {
    transition: opacity 0.3s;
}

.fade-out-in-enter-active {
    transition-delay: 0.3s;
}

.fade-out-in-enter,
.fade-out-in-leave-to {
    opacity: 0;
}
</style>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AlertWidget from "../Sidepanel/AlertWidget";
export default {
    data() {
        return {
            alertDropdownOpen: false,
            dropdownOpen: false,
            viewerVisualiseColours: false,
            openHistoricalPanel: false,
            selectedHistoricalAsset: null,
            selectedAsset: null,
            assetList: [
                // "Sag mill",
                // "Conveyor belt",
                // "Cyclone",
                // "Pump Warman #1",
                // "Pump Warman #2",
            ]
        };
    },
    components: {
        AlertWidget
    },
    computed: {
        ...mapState({
            alerts: state => state.alerts.alerts,
            historicalAlerts: state => state.alerts.historicalAlerts,
            viewables: state => state.assets.viewables,
            assets: state => state.assets.assembilies
        }),
        ...mapGetters({
            getExtensionById: "viewer/getExtensionById"
        })
    },
    async mounted() {
        await this.fetchAlerts({
            assemblies: []
        });

        await this.fetchAssets();

        this.assets.forEach(obj => {
            this.assetList.push(obj);
        });

        this.selectedHistoricalAsset = this.assetList[0].guid;

        this.colourRoom();
        await this.fetchAlertsFromStore("");
        await this.fetchHistoricalAlertsFromStore("");
    },
    methods: {
        ...mapActions({
            fetchAlerts: "alerts/fetchAlerts",
            fetchHistoricalAlerts: "alerts/fetchHistoricalAlerts",
            fetchViewables: "assets/fetchViewables",
            fetchAssets: "assets/fetchAssembilies",
            markAlertRead: "alerts/markRead"
        }),

        async markAllRead() {
            await this.markAlertRead({
                payload: {
                    assemblies: null,
                    recent_only: null,
                    guids: []
                }
            });

            await this.fetchAlerts({
                assemblies: []
            });
            this.dropdownOpen = false;
        },

        async fetchAlertsFromStore(event) {
            if (!event?.type) {
                const filter =
                    this.selectedAsset === "" || this.selectedAsset === null
                        ? {
                              unreadOnly: true,
                              unexpired_only: false,
                              recent_only: false
                          }
                        : {
                              assemblies: event,
                              unreadOnly: true,
                              unexpired_only: false,
                              recent_only: false
                          };

                await this.fetchAlerts(filter);
            }
        },

        async fetchHistoricalAlertsFromStore(event) {
            if (!event?.type) {
                await this.fetchHistoricalAlerts({
                    assemblies: [this.selectedHistoricalAsset]
                });
            }
        },

        async colourRoom() {
            await this.fetchViewables();

            while (
                !(await this.getExtensionById("BIMAcademy.ColourObjects")
                    .instance)
            ) {
                await new Promise(r => setTimeout(r, 500));
            }

            const instance = await this.getExtensionById(
                "BIMAcademy.ColourObjects"
            ).instance;

            instance.clearColours();

            if (this.viewerVisualiseColours) {
                for (let i = 0; i < this.viewables.length; i++) {
                    const assemblyGuid = this.viewables[i].assembly.guid;
                    let level = "";
                    let colour = "";

                    for (let x = 0; x < this.alerts.notifications.length; x++) {
                        if (
                            this.alerts.notifications[x].notification
                                .assemblies[0].guid === assemblyGuid
                        ) {
                            level = this.alerts.notifications[x].notification
                                .level;
                            break;
                        }
                    }

                    if (level === "success" || level === "info") {
                        colour = "#059668";
                    } else if (level === "warning") {
                        colour = "#f59f0b";
                    } else if (level === "error") {
                        colour = "#dc2626";
                    } else colour = "#059668";

                    instance.setObjectColour(
                        this.viewables[i].geometry,
                        colour
                    );
                }
            }
        }
    }
};
</script>
