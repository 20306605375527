import Vue from "vue";
import Vuex from "vuex";
import viewer from "./modules/viewer";
import account from "./modules/account";
import assets from "./modules/assets";
import weather from "./modules/weather";
import documents from "./modules/documents";
import alerts from "./modules/alerts";
import components from "./modules/components";

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    modules: {
        viewer,
        account,
        assets,
        weather,
        documents,
        components,
        alerts
    }
});
