<script>
import { Line } from "vue-chartjs";

import "chartjs-plugin-streaming";

export default {
    extends: Line,
    props: ["dataLabel", "datasets", "dataValue"],
    data() {
        return {
            datacollection: null,
            dataset: [
                {
                    label: this.dataLabel,
                    backgroundColor: "rgba(5, 150, 105, 0.4)",
                    borderColor: "rgba(5, 150, 105, 0.6)",
                    data: []
                }
            ]
        };
    },
    mounted() {
        this.fillData();
        this.renderChart(this.datacollection, {
            scales: {
                xAxes: [
                    {
                        type: "realtime",
                        realtime: {
                            onRefresh: this.onRefresh,
                            delay: 2000,
                            refresh: 1000
                        }
                    }
                ]
            },
            responsive: true,
            maintainAspectRatio: false
        });
    },
    methods: {
        async fillData() {
            this.datacollection = {
                labels: this.dataLabel,
                datasets: this.dataset
            };
        },
        onRefresh(chart) {
            var yData = this.dataValue != null ? this.dataValue : Math.random();
            var now = Date.now();

            chart.config.data.datasets.forEach(function(dataset) {
                dataset.data.push({
                    x: now,
                    y: yData
                });
            });
        }
    }
};
</script>
