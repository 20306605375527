<template>
    <div class="w-full my-4 bg-green-50 border-green-200 border rounded-lg p-4">
        <div v-if="displayName">
            <span class="text-2xl"
                ><strong>{{ displayName }}</strong></span
            >
        </div>
        <div v-if="description">
            <strong>Description: </strong>{{ description }}
        </div>
        <div v-if="serial"><strong>Serial: </strong>{{ serial }}</div>
        <div v-if="quantity"><strong>Quantity: </strong>{{ quantity }}</div>
        <div v-if="componentType" class="mt-2 ">
            <span class="text-xl  ">Component Type Details </span>
            <div class="ml-4">
                <div><strong>Name: </strong>{{ componentType.name }}</div>
                <div v-if="componentType.description">
                    <strong>Description: </strong
                    >{{ componentType.description }}
                </div>
                <div v-if="componentType.category">
                    <strong>Category: </strong>{{ componentType.category }}
                </div>
                <div v-if="componentType.model_number">
                    <strong>Model Number: </strong
                    >{{ componentType.model_number }}
                </div>
                <div >
                    <strong>Consumable: </strong>
                    <font-awesome-icon
                        v-if="componentType.is_consumable"
                        class="text-green-600 ml-1 mb-1 align-middle"
                        :icon="['fal', 'check']"
                        size="1x"
                    />
                    <font-awesome-icon
                        v-if="!componentType.is_consumable"
                        class="text-red-600 ml-1 mb-1  align-middle "
                        :icon="['fal', 'times']"
                        size="1x"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        displayName: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        serial: {
            type: String,
            default: null
        },
        quantity: {
            type: Number,
            default: null
        },
        componentType: {
            type: Object,
            default: null
        }
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        updateValue: function() {
            this.$emit("click", this.type);
        }
    }
};
</script>
